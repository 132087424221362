<!--
 * @Author: wjc
 * @Date: 2023-11-07 16:38:16
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-11 15:00:02
 * @Description: 
-->
<template>
  <NuxtLink
    :to="to"
    :class="classs"
    :target="target"
    rel="noopener noreferrer"
    @click="$emit('click')"
  >
    <slot></slot>
  </NuxtLink>
</template>

<script lang="ts" setup>
  import type { RouteLocationRaw } from '#vue-router'

  defineOptions({
    name: 'MLink',
  })

  export type LinkTo = RouteLocationRaw & {
    path: string
  }

  const props = defineProps<{
    to: LinkTo | string
    target?: string
  }>()

  defineEmits<{
    (e: 'click'): void
  }>()

  const classs = ref(
    'm-link transition-colors duration-300 hover:text-primary-color cursor-pointer font-s-1.4rem lt-xs:font-s-1.6rem'
  )
</script>
